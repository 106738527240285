export type CmsStaticSupportTopic = {
  readonly topic: string;
  readonly category: string;
  readonly answer: string;
};

export type CmsStaticSupport = {
  readonly faqTopics: CmsStaticSupportTopic[];
  readonly workingHours: string[];
  readonly email: string;
};

export function createCmsStaticSupportFromHttpData(httpData: Record<string, any>): CmsStaticSupport {
  const faqTopics: CmsStaticSupportTopic[] = [];

  for (const inner of httpData['data']['attributes']['faq']) {
    const category: string = inner['faqCategory']['data']['attributes']['supportCategory'];
    for (const inner2 of inner['faqTopics']) {
      const topic: CmsStaticSupportTopic = {
        topic: inner2['topic'],
        category: category,
        answer: inner2['answer']
      };
      faqTopics.push(topic);
    }
  }

  return {
    faqTopics: faqTopics,
    workingHours: httpData['data']['attributes']['workingHours'].map(
      (inner: Record<string, any>) => inner['workingHoursField']
    ),
    email: httpData['data']['attributes']['email']
  };
}
