import { releaseVersion } from '@tmf-configs/release-version';
import { env } from '@tmf-env/janusz-casino/env.prod';
import { boostrapApplication } from '@tmf-janusz-casino-desktop-app/app';
import { bootstrapAppI18n } from '@tmf-janusz-casino-desktop-app/app-i18n';
import '@tmf-polyfills';
import { initGcpErrorReporting } from '@tmf-shared-platform/gcp-error-reporting/init-gcp-error-reporting';
import { appInitRedirector } from '@tmf-shared-utils/app-init-redirector';
import { litswAppInitializer } from '@tmf-ui-shared/litsw/litsw-app-initializer';
import { setReleaseVersion } from '@tmf-ui-shared/misc/set-release-version';

async function main(): Promise<void> {
  appInitRedirector();
  setReleaseVersion(releaseVersion, document);
  initGcpErrorReporting('jz-desktop', releaseVersion);
  // eslint-disable-next-line @typescript-eslint/typedef
  import('@tmf-ui-shared/svg-icons/svg-icons-loader').then((mod) => mod.svgIconsLoader.load());
  litswAppInitializer('/desktop-sw.js', { enabled: true });
  await bootstrapAppI18n(env);
  boostrapApplication(env);
}
main();
