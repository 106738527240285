import { createContext } from '@lit/context';
import { TmfLitElement, registerElement } from '@tmf-shared-classes/tmf-lit-element';
import { filterIsTruthy } from '@tmf-shared-misc/rx-operators';
import { BottomSheetKindStandalone } from '@tmf-ui-shared/misc/enums';
import { BottomSheetManifest } from '@tmf-ui-shared/misc/types';
import { Observable, Subject, from, map, mergeMap, of, scan, tap } from 'rxjs';

export class BottomSheetStandaloneLazyResolverService {
  private _alreadyResolved: BottomSheetKindStandalone[] = [];
  private _manifestsLoaded!: BottomSheetManifest[];

  private _loadNewModal$: Subject<BottomSheetManifest> = new Subject<BottomSheetManifest>();

  public resolve(kind: BottomSheetKindStandalone) {
    return (state: boolean): void => {
      if (!this._alreadyResolved.includes(kind) && state) {
        this._alreadyResolved.push(kind);
        const foundManifest: BottomSheetManifest | undefined = this._manifestsLoaded?.find(
          (manifest: BottomSheetManifest) => manifest.name === kind
        );
        if (foundManifest) {
          this._loadNewModal$.next(foundManifest);
        }
      }
    };
  }

  public getBottomSheetsElementsTags(manifests: BottomSheetManifest[]): Observable<string[]> {
    this._manifestsLoaded = manifests;
    return this._loadNewModal$.pipe(
      mergeMap((lazyModal: BottomSheetManifest) => {
        if (lazyModal.loadElement) {
          return from(lazyModal.loadElement());
        }
        if (lazyModal.loadElementTmf) {
          return from(lazyModal.loadElementTmf()).pipe(
            tap((mod: typeof TmfLitElement) => registerElement(mod)),
            map((mod: typeof TmfLitElement) => mod.elementSelector)
          );
        }
        return of(undefined);
      }),
      filterIsTruthy(),
      scan((modals: string[], newModal: string) => [...modals, newModal], [])
    );
  }
}
export const bottomSheetStandaloneLazyResolverService: BottomSheetStandaloneLazyResolverService =
  new BottomSheetStandaloneLazyResolverService();

// eslint-disable-next-line @typescript-eslint/typedef
export const bottomSheetStandaloneLazyResolverServiceContext = createContext<BottomSheetStandaloneLazyResolverService>(
  'bottomSheetStandaloneLazyResolverService'
);
