import { isDefined } from '@tmf-shared-misc/pure-utils';
import { QueryParams } from '@tmf-shared-platform/activated-route/activated-route';
import { HistoryUrl } from '@tmf-shared-platform/history-url/history-url';
import { PathRouteConfig, Router, Routes } from '@tmf-shared-platform/router/internal';
import { notFoundRoutePath } from '@tmf-ui-routes/paths/not-found.route-path';
import { auditTime, fromEvent } from 'rxjs';
import { routerGoBack, routerUpdateReplaceUrl, routerUpdateUrl } from './router-utils';

export class RouterNavigatorService {
  private _router: Router | undefined;
  private _historyUrl: HistoryUrl | undefined;

  public bindRouter(router: Router): void {
    this._router ??= router;
    this._initWatcher();
  }

  public bindHistoryUrl(historyUrl: HistoryUrl): void {
    this._historyUrl = historyUrl;
  }

  public navigateTo(path: string, queryParams?: QueryParams, state?: Record<string, any>): void {
    if (!this._router) {
      return;
    }
    routerUpdateUrl(this._router, path, queryParams, state);
  }

  public goBack(): void {
    if (!this._historyUrl) {
      return;
    }
    routerGoBack(this._historyUrl);
  }

  private _initWatcher(): void {
    fromEvent(window, 'routerUpdated')
      .pipe(auditTime(1500))
      .subscribe(() => this._checkNotFound());
  }

  private _checkNotFound(): void {
    const findLastChildRoute = (routes: Routes): Routes => {
      if (routes._childRoutes.length > 0) {
        return findLastChildRoute(routes._childRoutes[0]);
      } else {
        return routes;
      }
    };

    const lastChild: Routes = findLastChildRoute(this._router!);
    const path: string | undefined = this._extractPathFromLastChild(lastChild);
    if (window.location.pathname.includes('not-found')) {
      return;
    }
    if (!window.location.pathname.endsWith(path as string)) {
      routerUpdateReplaceUrl(
        this._router!,
        notFoundRoutePath(), // + `?notFoundpathname=${window.location.pathname}`,
        undefined,
        { notFoundPath: window.location.pathname }
      );
    }
  }

  private _extractPathFromLastChild(lastChild: Routes): string | undefined {
    let path: string | undefined = (lastChild._currentRoute as PathRouteConfig)?.path;
    if (!isDefined(path)) {
      return undefined;
    }
    for (const [key, value] of Object.entries(lastChild._currentParams)) {
      if (path.includes(`:${key}`)) {
        path = path!.replace(`:${key}`, value as string);
      }
    }
    return path;
  }
}

export const routerNavigatorService: RouterNavigatorService = new RouterNavigatorService();
