declare const global: any;
class I18nRouteCache {
  [name: string]: string;

  private static indexedHandler: ProxyHandler<I18nRouteCache> = {
    get(target: I18nRouteCache, property: string) {
      if (typeof global !== 'undefined' && global && global['disableI18nRouteCache']) {
        return undefined;
      }
      return target[property];
    },
    set(target: I18nRouteCache, property: string, value: string): boolean {
      if (typeof global !== 'undefined' && global && global['disableI18nRouteCache']) {
        return true;
      }
      target[property] = value;
      return true;
    }
  };

  constructor() {
    return new Proxy(this, I18nRouteCache.indexedHandler);
  }
}

// impure
export const i18nCache: I18nRouteCache = new I18nRouteCache();
