import { css, CSSResult } from 'lit';

export const appStyles: CSSResult = css`
  :host {
    display: block;
    width: 100%;
    height: 100vh;
    min-height: -webkit-fill-available;
    background-color: var(--white);
    font-family: 'Poppins';
  }
`;

export const appVariables: CSSResult = css`
  :host {
    /* BASIC */
    --white: #fff;
    --black: #000;
    --cupa: red;

    /* NEUTRAL */
    --neutral-10: #f0f0f0;
    --neutral-20: #d6d6d6;
    --neutral-30: #bdbdbd;
    --neutral-40: #a3a3a3;
    --neutral-50: #8a8a8a;
    --neutral-60: #707070;
    --neutral-70: #575757;
    --neutral-80: #242424;
    --neutral-90: #0a0a0a;
    --neutral-100: hsl(0deg, 0%, 4%, 0.1);

    /* BRAND */
    --brand-10: #feebeb;
    --brand-20: #fcbbbb;
    --brand-30: #fa8a8a;
    --brand-40: #f85959;
    --brand-50: #f62828;
    --brand-60: #e10a0a;
    --brand-70: #b00808;
    --brand-80: #7f0606;
    --brand-90: #4e0303;
    --brand-100: hsl(0deg, 92%, 56%, 0.1);

    /* SUCCESS */
    --success-10: #dbefdc;
    --success-20: #b7dfb9;
    --success-30: #94cf96;
    --success-40: #70bf73;
    --success-50: #4caf50;
    --success-60: #3d8c40;
    --success-70: #2e6930;
    --success-80: #1e4620;
    --success-90: #0f2310;
    --success-100: hsl(122, 39%, 49%, 0.1);

    /* WARNING */
    --warning-10: #ffeacc;
    --warning-20: #ffd699;
    --warning-30: #ffc166;
    --warning-40: #ffad33;
    --warning-50: #ff9800;
    --warning-60: #cc7a00;
    --warning-70: #995b00;
    --warning-80: #663d00;
    --warning-90: #331e00;
    --warning-100: hsl(36, 100%, 50%, 0.1);

    /* INFO */
    --info-10: #d6daff;
    --info-20: #adb4ff;
    --info-30: #858fff;
    --info-40: #5c69ff;
    --info-50: #3344ff;
    --info-60: #2936cc;
    --info-70: #1f2999;
    --info-80: #141b66;
    --info-90: #0a0e33;
    --info-100: hsl(235, 100%, 60%, 0.1);

    /* RADIUSES */
    --radius-1: 8px;
    --radius-2: 12px;
    --radius-3: 16px;
    --radius-4: 24px;
    --radius-5: 32px;
    --radius-6: 40px;
    --radius-7: 48px;

    /* SPACING */
    --space-1: 4px;
    --space-2: 8px;
    --space-3: 12px;
    --space-4: 16px;
    --space-5: 20px;
    --space-6: 24px;
    --space-7: 32px;
    --space-8: 40px;
    --space-9: 48px;
    --space-10: 56px;
    --space-11: 64px;
    --space-12: 80px;
    --space-13: 96px;
    --space-14: 128px;
    --space-15: 160px;
    --space-16: 192px;
    --space-17: 224px;
    --space-18: 256px;
    --space-19: 512px;

    /* IOS */
    --safe-area-top: env(safe-area-inset-top);
    --safe-area-bottom: env(safe-area-inset-bottom);
    --safe-area-left: env(safe-area-inset-left);
    --safe-area-right: env(safe-area-inset-right);

    /* SIZES */
    --header-height: calc(56px + var(--safe-area-top));
    --bottom-nav-height: calc(60px + var(--safe-area-bottom));

    /* SIZES */
    --desktop-center-size: 1330px;
  }

  @media screen and (max-width: 1678px) {
    :host {
      --desktop-center-size: 100%;
    }
  }
`;
