import { cmsSeoSettingsForGame, CmsSeoSettingsForGame } from '@tmf-logic-cms/services/cms-seo-settings-for-game';
import { Bloc } from '@tmf-shared-classes/bloc';
import { CmsSeoSettings, CmsSeoSettingsConfig } from '@tmf-shared-models/cms-seo-settings';
import { CmsApi, cmsApi } from 'packages/logic/api-access/http/api/cms.api';
import { combineLatest, map, Observable, ReplaySubject } from 'rxjs';

export class CmsSeoJsonLdUpdaterBloc extends Bloc {
  public currentUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(
    private _cmsApi: CmsApi,
    private _cmsSeoSettingsForGame: CmsSeoSettingsForGame
  ) {
    super();
  }

  public getJsonLdContent(): Observable<Record<string, any> | undefined> {
    return combineLatest([
      this.currentUrl$,
      this._cmsApi.getCmsSeoSettings(),
      this._cmsSeoSettingsForGame.getSeoSettingsConfig()
    ]).pipe(
      map(([url, settings, settingsForGame]: [string, CmsSeoSettings, CmsSeoSettingsConfig | undefined]) => {
        return settingsForGame?.jsonLd ?? settings.perUrl[url]?.jsonLd ?? settings.default.jsonLd;
      })
    );
  }
}

export function provideCmsSeoJsonLdUpdaterBloc(): CmsSeoJsonLdUpdaterBloc {
  return new CmsSeoJsonLdUpdaterBloc(cmsApi, cmsSeoSettingsForGame);
}
