export type CmsSeoSettingsConfig = {
  readonly metaCanonical: string | undefined;
  readonly metaDescription: string | undefined;
  readonly metaRobots: string | undefined;
  readonly metaTitle: string | undefined;
  readonly preFooterHtml: string | undefined;
  readonly jsonLd: Record<string, any> | undefined;
};

export type CmsSeoSettings = {
  readonly default: CmsSeoSettingsConfig;
  readonly perUrl: Record<string, CmsSeoSettingsConfig>;
};

function createCmsSeoSettingsConfig(json: Record<string, any>): CmsSeoSettingsConfig {
  return {
    metaCanonical: json['canonical'] ?? undefined,
    metaDescription: json['description'] ?? undefined,
    metaRobots: json['robots'] ?? undefined,
    metaTitle: json['title'] ?? undefined,
    preFooterHtml:
      json['footerHtml']?.map((innerJson: Record<string, any>) => innerJson['content']).join() ?? undefined,
    jsonLd: json['faq'] && Object.keys(json['faq']).length > 0 ? json['faq'] : undefined
  };
}

export function createCmsSeoSettingsConfigFromHttpData(
  httpData: Record<string, any>
): CmsSeoSettingsConfig | undefined {
  if (httpData['details']) {
    return undefined;
  }
  return createCmsSeoSettingsConfig(httpData);
}

export function createCmsSeoSettingsFromHttpData(httpData: Record<string, any>): CmsSeoSettings {
  const perUrl: Record<string, CmsSeoSettingsConfig> = {};

  for (const [key, value] of Object.entries(httpData)) {
    if (key === 'default') {
      continue;
    }
    perUrl[key] = createCmsSeoSettingsConfig(value);
  }

  return {
    default: createCmsSeoSettingsConfig(httpData['default']),
    perUrl: perUrl
  };
}
