import { Observable, ReplaySubject, first, fromEvent, map, mergeMap, startWith } from 'rxjs';
import { AnalyticsAction, ConsentAction } from './types';
import { analyticsTypesMap } from './types-mapper';

interface WindowLike extends Window {
  gtag?: (event: string, action: ConsentAction, data: Record<string, any>) => void;
  dataLayer?: {
    push: (payload: Record<string, any>) => void;
  };
  isGtmLazyLoaded?: boolean;
}

type DataLayerQueuePayload = {
  action: AnalyticsAction;
  payload?: Record<string, any>;
};

type GtagQueuePayload = {
  event: string;
  action: ConsentAction;
  data: Record<string, any>;
};

export class GtmService {
  private _dataLayerQueue$: ReplaySubject<DataLayerQueuePayload> = new ReplaySubject<DataLayerQueuePayload>();
  private _gtagQueue$: ReplaySubject<GtagQueuePayload> = new ReplaySubject<GtagQueuePayload>();

  constructor(private _window: WindowLike) {
    this._init();
  }

  private _init(): void {
    const gtmLoaded$: Observable<boolean> = fromEvent(this._window, 'gtmLoaded').pipe(
      map(() => true),
      startWith(this._window.isGtmLazyLoaded),
      first((event: boolean | undefined): event is boolean => Boolean(event))
    );

    gtmLoaded$
      .pipe(
        first(() => Boolean(this._window.dataLayer)),
        mergeMap(() => this._dataLayerQueue$)
      )
      .subscribe((dataLayerQueue: DataLayerQueuePayload) => {
        this._window.dataLayer!.push({
          event: analyticsTypesMap(dataLayerQueue.action),
          ...(dataLayerQueue.payload ?? {})
        });
      });

    gtmLoaded$
      .pipe(
        first(() => Boolean(this._window.gtag)),
        mergeMap(() => this._gtagQueue$)
      )
      .subscribe((gtagQueue: GtagQueuePayload) => {
        this._window.gtag!(gtagQueue.event, gtagQueue.action, gtagQueue.data);
      });
  }

  public gtag(event: string, action: ConsentAction, data: Record<string, any>): void {
    this._gtagQueue$.next({ event, action, data });
  }

  public pushDataLayer(action: AnalyticsAction, payload?: Record<string, any>): void {
    this._dataLayerQueue$.next({ action, payload });
  }
}

export const gtmService: GtmService = new GtmService(window);
