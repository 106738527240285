import { device } from '@tmf-device';
import { filterIsTruthy } from '@tmf-shared-misc/rx-operators';
import { routerUpdateBottomSheetFragment, routerUpdateModalFragment } from '@tmf-shared-platform/router/router-utils';
import { BottomSheetKind, ModalKind } from '@tmf-ui-shared/misc/enums';
import { filter } from 'rxjs';
import { CookiesConsent, cookiesConsent } from './cookies-consent';
import { CookiesConsentManager, cookiesConsentManager } from './cookies-consent-manager';
import { CookiesConsentType } from './cookies-consent-types';

export class CookiesConsentService {
  constructor(
    private _cookiesConsent: CookiesConsent,
    private _cookiesConsentManager: CookiesConsentManager
  ) {}

  public init(): void {
    this._cookiesConsent.acceptedCookiesConsent$
      .pipe(filterIsTruthy())
      .subscribe((currentConsent: CookiesConsentType[]) => this._cookiesConsentManager.set(currentConsent));

    this._cookiesConsent.acceptedCookiesConsent$
      .pipe(filter((cookiesConsent: CookiesConsentType[] | undefined) => !cookiesConsent))
      .subscribe(this._doNavigateToCookiesConsent);
  }

  private _doNavigateToCookiesConsent(): void {
    if (device === 'mobile') {
      routerUpdateBottomSheetFragment(BottomSheetKind.cookiesConsent);
      return;
    }
    routerUpdateModalFragment(ModalKind.cookiesConsent);
  }
}

export const cookiesConsentService: CookiesConsentService = new CookiesConsentService(
  cookiesConsent,
  cookiesConsentManager
);
