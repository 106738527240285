import { CookiesConsentManager, cookiesConsentManager } from '@tmf-ui-shared/cookies-consent/cookies-consent-manager';
import { GtmService, gtmService } from './gtm.service';
import { GtmCookieConsent } from './types';

export class GtmNotifierService {
  constructor(
    private _cookiesConsentManager: CookiesConsentManager,
    private _gtmService: GtmService
  ) {}

  public init(): void {
    this._cookiesConsentManager.getAnalyticsConsent().subscribe((consent: GtmCookieConsent) => {
      this._gtmService.gtag('consent', 'update', consent);
      this._gtmService.pushDataLayer('cookie-consent-update');
    });
  }
}

export const gtmNotifierService: GtmNotifierService = new GtmNotifierService(cookiesConsentManager, gtmService);
