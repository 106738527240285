import {
  CmsSeoMetaUpdaterBloc,
  provideCmsSeoMetaUpdaterBloc
} from '@tmf-logic-cms/bloc/cms-seo-meta-updater/cms-seo-meta-updater.bloc';
import { UnsubscribableController } from '@tmf-shared-classes/unsubscribable-controller';
import { transferRxNext } from '@tmf-shared-misc/rx-helpers';
import { ActivatedLang } from '@tmf-shared-platform/activated-lang/activated-lang';
import { ActivatedUrl } from '@tmf-shared-platform/activated-url/activated-url';
import { MetaService } from '@tmf-ui-shared/seo/meta.service';
import { TitleService } from '@tmf-ui-shared/seo/title.service';
import { SharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { ReactiveControllerHost } from 'lit';
import { mapToUpdatedSegment } from './pure-utils/map-to-updated-segment';

export class CmsSeoMetaUpdaterController extends UnsubscribableController<CmsSeoMetaUpdaterController> {
  constructor(
    protected override _host: ReactiveControllerHost,
    protected override _bloc: CmsSeoMetaUpdaterBloc,
    private _metaService: MetaService,
    private _titleService: TitleService,
    private _activatedUrlFn: () => ActivatedUrl,
    private _sharedSettingsFn: () => SharedSettings,
    private _activatedLangFn: () => ActivatedLang
  ) {
    super(_host);
  }

  public hostConnected(): void {
    this._bloc.currentHostname$.next(document.location.hostname);
    this._sub = this._activatedUrlFn().url$.subscribe(transferRxNext(this._bloc.currentUrl$));
    this._sub = this._activatedUrlFn().fullUrl$.subscribe(transferRxNext(this._bloc.currentFullUrl$));

    this._sub = this._activatedLangFn().currentLang$.subscribe(this._updateHtmlLang());
    this._sub = this._activatedUrlFn().fullUrl$.subscribe(this._updateHreflang());

    this._sub = this._bloc.getMetaTitle().subscribe((title: string) => this._titleService.setTitle(title));

    this._sub = this._bloc
      .getMetaDescription()
      .subscribe((description: string) => this._metaService.updateTag({ name: 'description', content: description }));

    this._sub = this._bloc
      .getMetaRobots()
      .subscribe((robots: string) => this._metaService.updateTag({ name: 'robots', content: robots }));

    this._sub = this._bloc.getMetaCanonical().subscribe(this._updateCanonicalUrl());
  }

  private _updateCanonicalUrl() {
    return (url: string): void => {
      const head: HTMLHeadElement = document.head;
      let element: HTMLLinkElement | null = document.querySelector(`link[rel='canonical']`);
      if (!element) {
        element = document.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', url);
    };
  }

  private _updateHtmlLang() {
    return (lang: string): void => {
      const htmlTag: HTMLHtmlElement = document.querySelector('html')!;
      htmlTag.lang = lang;
    };
  }

  private _updateHreflang() {
    return (url: string): void => {
      const envLangs: string[] = this._sharedSettingsFn().env.langs;

      const availableLangs: string[] = ['x-default', ...envLangs];
      const relationLinks: HTMLLinkElement[] = Array.from(document.querySelectorAll(`link[rel='alternate']`));

      for (const lang of availableLangs) {
        const hreflangLanguage: string = lang === 'x-default' ? envLangs[0] : lang;
        const updatedUrlByLangSegment: string = url
          .split('/')
          .map(mapToUpdatedSegment(hreflangLanguage, availableLangs))
          .join('/');

        if (relationLinks.length > envLangs.length) {
          relationLinks
            .find((htmlLinkElement: HTMLLinkElement) => htmlLinkElement.hreflang === lang)
            ?.setAttribute('href', updatedUrlByLangSegment);
        } else {
          const element: HTMLLinkElement = document.createElement('link') as HTMLLinkElement;
          document.head.appendChild(element);
          element.setAttribute('rel', 'alternate');
          element.setAttribute('hreflang', lang);
          element.setAttribute('href', updatedUrlByLangSegment);
        }
      }
    };
  }
}

export function provideCmsSeoMetaUpdaterController(
  host: ReactiveControllerHost,
  activatedUrlFn: () => ActivatedUrl,
  sharedSettingsFn: () => SharedSettings,
  activatedLangFn: () => ActivatedLang
): CmsSeoMetaUpdaterController {
  return new CmsSeoMetaUpdaterController(
    host,
    provideCmsSeoMetaUpdaterBloc(),
    new MetaService(),
    new TitleService(),
    activatedUrlFn,
    sharedSettingsFn,
    activatedLangFn
  );
}
