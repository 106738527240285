export class HttpClient {
  public async get<T>(url: string): Promise<T> {
    const response: Response = await fetch(url);
    const jsonData: T = await response.json();
    return jsonData;
  }

  public async post<T, R>(url: string, body: R): Promise<T> {
    const response: Response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    const jsonData: T = await response.json();
    return jsonData;
  }
}

export const httpClient: HttpClient = new HttpClient();
