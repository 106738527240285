export type FooterTextData = {
  title: string;
  contentTexts: string[];
};

export type FooterImage = {
  iconName: string;
  url: string | undefined;
};

export type CmsFooterData = {
  socials: FooterImage[];
  logos: FooterImage[];
  textData: FooterTextData[];
};

function createImage(json: Record<string, any>): FooterImage {
  return {
    iconName: json['iconClassName'],
    url: json['redirectUrl'] ?? undefined
  };
}

function createTextData(json: Record<string, any>): FooterTextData {
  return {
    title: json['title'],
    contentTexts: [json['textContent']]
  };
}

export function createCmsFooterDataFromHttpData(httpData: Record<string, any>): CmsFooterData {
  const dataJson: Record<string, any> = httpData['data']['attributes'];
  return {
    socials: dataJson['socialMedia'].map(createImage),
    logos: dataJson['logos'].map(createImage),
    textData: dataJson['licence'].map(createTextData)
  };
}
