import { CookiesConsentType } from '@tmf-ui-shared/cookies-consent/cookies-consent-types';
import { AnalyticsAction, GtmCookieConsent, GtmCookieConsentKeys } from './types';

export function analyticsTypesMap(action: AnalyticsAction): string {
  const mapper: Record<string, string> = {
    'cookie-consent-update': 'cookie_consent_update',
    'register-open': 'register_start',
    'register-quick-end': 'register_quick_end_new',
    'register-full-end': 'register_full_end_new',
    'deposit-start': 'deposit_start',
    'deposit-confirm': 'deposit_confirm',
    deposit: 'deposit'
  };
  return mapper[action];
}

export function consentTypesMap(currentConsent: CookiesConsentType[]): GtmCookieConsent {
  const consentMapGTag: Record<GtmCookieConsentKeys, CookiesConsentType> = {
    ad_storage: 'Marketing',
    ad_user_data: 'Marketing',
    ad_personalization: 'Marketing',
    analytics_storage: 'Analytics',
    personalization_storage: 'Preferences'
  };

  const consentGTag: GtmCookieConsent = {};
  for (const [key, value] of Object.entries(consentMapGTag)) {
    consentGTag[key] = currentConsent.includes(value) ? 'granted' : 'denied';
  }

  return consentGTag;
}
