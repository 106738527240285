import { createContext } from '@lit/context';
import { Env } from '@tmf-env/types';

export class SharedSettings {
  private _env!: Env;

  public set env(env: Env) {
    if (this._env) {
      throw new Error('you can setup environment just once');
    }
    this._env = env;
  }

  public get env(): Env {
    return this._env;
  }
}

export const sharedSettings: SharedSettings = new SharedSettings();

// eslint-disable-next-line @typescript-eslint/typedef
export const sharedSettingsContext = createContext<SharedSettings>('SharedSettings');
