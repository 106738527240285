import { isDefined } from '@tmf-shared-misc/pure-utils';
import { QueryParams } from '@tmf-shared-platform/activated-route/activated-route';
import { historyState } from '@tmf-shared-platform/history-state/history-state';
import { HistoryUrl } from '@tmf-shared-platform/history-url/history-url';
import { Router } from '@tmf-shared-platform/router/internal';
import { reverse } from 'rambdax';

type CreateNewUrl = {
  queryParams?: string;
  fragment?: string;
};
function createNewUrl(config: CreateNewUrl): string {
  let baseUrl: string = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  if (config.queryParams && config.queryParams.length > 0) {
    baseUrl += `?${config.queryParams}`;
  }
  if (config.fragment && config.fragment.length > 0) {
    baseUrl += `#${config.fragment}`;
  }
  return baseUrl;
}

export function routerUpdateUrl(
  router: Router,
  newUrl: string,
  queryParams?: QueryParams,
  state?: Record<string, any>
): void {
  const queryParamsString: string | undefined = queryParams
    ? stringifyQueryParams(new URLSearchParams(''), queryParams)
    : undefined;

  let newState: Record<string, any> = {
    path: newUrl
  };
  if (state) {
    newState = {
      ...newState,
      ...state
    };
  }

  window.history.pushState(newState, '', newUrl + (queryParamsString ? `?${queryParamsString}` : ''));
  historyState.stateMightChanged();
  router.goto(newUrl);
}

export function routerUpdateReplaceUrl(
  router: Router,
  newUrl: string,
  queryParams?: QueryParams,
  state?: Record<string, any>
): void {
  const queryParamsString: string | undefined = queryParams
    ? stringifyQueryParams(new URLSearchParams(''), queryParams)
    : undefined;

  let newState: Record<string, any> = {
    path: newUrl
  };
  if (state) {
    newState = {
      ...newState,
      ...state
    };
  }

  window.history.replaceState(newState, '', newUrl + (queryParamsString ? `?${queryParamsString}` : ''));
  historyState.stateMightChanged();
  router.goto(newUrl);
}

export function routerUpdateUrlQueryParamsOrFragment(newUrl: string): void {
  window.history.pushState({ ...window.history.state, path: newUrl }, '', newUrl);

  historyState.stateMightChanged();
  window.dispatchEvent(new Event('routerUpdated'));
}

export function routerBackFromOpenGameToGameListCategory(router: Router, historyUrl: HistoryUrl): void { 
  const queryParamsString: string = historyUrl.history[historyUrl.history.length - 2];
  window.history.replaceState({ ...window.history.state, path: queryParamsString }, '', queryParamsString);
  historyState.stateMightChanged();
  router.goto(window.location.pathname);
}

export function routerGoBack(historyUrl: HistoryUrl): void {
  const findGoBackCount = (): number | undefined => {
    const currentUrl: string = historyUrl.history[historyUrl.history.length - 1];
    const reversed: string[] = reverse(historyUrl.history);
    for (let i: number = 1; i < reversed.length; i++) {
      if (getPath(reversed[i]) !== getPath(currentUrl)) {
        return i;
      }
    }
    return undefined;
  };

  const getPath = (url: string): string => {
    return url.split('#')[0].split('?')[0];
  };

  const goBack = (steps?: number): void => {
    if (steps) {
      window.history.go(steps);
    } else {
      window.history.back();
    }
    window.dispatchEvent(new Event('routerUpdated'));
  };

  const backIndex: number | undefined = findGoBackCount();
  if (isDefined(backIndex)) {
    const indexUnsigned: number = backIndex * -1;
    goBack(indexUnsigned);
    return;
  }
  goBack();
}

function stringifyQueryParams(currentQueryParams: URLSearchParams, newQueryParams: QueryParams): string {
  for (const [key, value] of Object.entries(newQueryParams)) {
    if (value === undefined) {
      currentQueryParams.delete(key);
    } else {
      currentQueryParams.set(key, value!.toString());
    }
  }
  return currentQueryParams.toString();
}

export function routerUpdateQueryParams(currentQueryParams: URLSearchParams, newQueryParams: QueryParams): void {
  const newUrl: string = createNewUrl({ queryParams: stringifyQueryParams(currentQueryParams, newQueryParams) });
  routerUpdateUrlQueryParamsOrFragment(newUrl);
}

export function routerUpdateFragment(newFragment: string | undefined): void {
  const newUrl: string = createNewUrl({
    queryParams: new URLSearchParams(window.location.search).toString(),
    fragment: newFragment
  });
  routerUpdateUrlQueryParamsOrFragment(newUrl);
}

export function routerUpdateModalFragment(modalName: string): void {
  routerUpdateFragment(`modal-${modalName}`);
}

export function routerUpdateModalFragmentWithParams(modalName: string, params: string[]): void {
  if (params.length > 0) {
    routerUpdateFragment(`modal-${modalName}$${params.join('~')}`);
    return;
  }
  routerUpdateFragment(`modal-${modalName}`);
}

export function routerUpdateBottomSheetFragment(modalName: string): void {
  routerUpdateFragment(`bottom-sheet-${modalName}`);
}
