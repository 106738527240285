import { Subscription, SubscriptionLike } from 'rxjs';
import { TmfLitElement } from './tmf-lit-element';

export abstract class UnsubscribableTmfLitElement extends TmfLitElement {
  private _subscriptions: Subscription = new Subscription();

  set _sub(sub: SubscriptionLike | undefined) {
    if (Boolean(sub)) {
      this._subscriptions.add(sub);
    }
  }

  public override disconnectedCallback(): void {
    super.disconnectedCallback();
    this._subscriptions.unsubscribe();
  }
}
