import { extractCookies } from '@tmf-shared-platform/cookie-storage/pure-utils/extract-cookies';
import { AppId } from 'configs/app-id';
import { sendToErrorReporting, sendToErrorUnhandledReporting } from './internal/send-to-error-reporting';

function prepareUnhandledError(error: PromiseRejectionEvent): Error | undefined {
  try {
    return {
      name: error.reason.name,
      message: error.reason.message,
      stack: error.reason.stack
    };
  } catch (err) {
    return undefined;
  }
}

export function initGcpErrorReporting(appId: AppId, releaseVersion: string): void {
  window.addEventListener('unhandledrejection', (error: PromiseRejectionEvent) => {
    sendToErrorUnhandledReporting(
      appId,
      releaseVersion,
      prepareUnhandledError(error),
      document.location.pathname,
      extractCookies(document.cookie)
    );
  });

  window.addEventListener('error', (error: ErrorEvent) => {
    if (error.error) {
      const errorToSend: Error = {
        name: error.error.name,
        message: error.error.message,
        stack: error.error.stack
      };
      sendToErrorReporting(
        appId,
        releaseVersion,
        errorToSend,
        document.location.pathname,
        extractCookies(document.cookie)
      );
    }
  });
}
