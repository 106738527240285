import { isDefined } from '@tmf-shared-misc/pure-utils';

const memory: Record<string, string> = {};

function extractVersionNumber(): string {
  const host: string = document.location.host;
  if (isDefined(memory[host])) {
    return memory[host];
  }
  let version: string = '';
  for (const char of host) {
    if (!isNaN(Number(char))) {
      version += char;
    }
  }
  memory[host] = version;
  return version;
}

export function resolveUrlWithVersionNumber(url: string): string {
  return url.replace('{versionNumber}', extractVersionNumber());
}
