export type CmsBonus = {
  readonly title: string;
  readonly contentId: string | undefined;
  readonly campaignId: string | undefined;
  readonly category: string | undefined;
  readonly subTitle: string;
  readonly imageUrl: string;
  readonly termsHtml: string | undefined;
  readonly termsWithDepositLink: boolean;
};

export function createCmsBonusesFromHttpData(httpData: Record<string, any>): CmsBonus[] {
  const container: CmsBonus[] = [];

  for (const data of httpData['data']) {
    const cmsBonus: CmsBonus = {
      title: data['attributes']['title'],
      contentId: data['attributes']['contentId'] ?? undefined,
      campaignId: data['attributes']['campaignId'] ?? undefined,
      category: undefined,
      subTitle: data['attributes']['subTitle'],
      imageUrl: data['attributes']['image']['data']['attributes']['url'],
      termsHtml:
        data['attributes']['termsHTML'] && data['attributes']['termsHTML'].length > 0
          ? data['attributes']['termsHTML']
          : undefined,
      termsWithDepositLink: false
    };
    container.push(cmsBonus);
  }

  return container;
}
