export function isError<T, R extends Required<{ isError: true }>>(input: T | R | undefined): input is R {
  if (input === undefined) {
    return false;
  }
  return (input as R).isError !== undefined;
}

export function toDouble(value: string | number | boolean): number {
  return Number(Number(value).toFixed(2));
}

export function createTuple2<A, B>(a: A, b: B): [A, B] {
  return [a, b];
}

export const createTuple2C =
  <A, B>(b: B) =>
  (a: A): [A, B] =>
    createTuple2(a, b);

export function convertLocalDateToUTC(date: Date): Date {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60_000);
}

export function isUndefined<T>(value: T | undefined): value is undefined {
  return typeof value === 'undefined';
}

export function isDefined<T>(value: T): value is NonNullable<T> {
  return typeof value !== 'undefined' && value !== null;
}
