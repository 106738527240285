import { UserDataInfo, userDataInfo } from '@tmf-logic-auth/services/user-data-info';
import { UserLocation } from '@tmf-shared-models/user-location';
import { SharedSettings, sharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { Observable, map, of } from 'rxjs';

export class CheckCountryBlockage {
  constructor(
    private _sharedSettings: SharedSettings,
    private _userDataInfo: UserDataInfo
  ) {}

  public shouldShowBlockagePage(): Observable<boolean> {
    if (!this._sharedSettings.env.allowedCountries) {
      return of(false);
    }
    return this._userDataInfo.userLocation$.pipe(
      map((userLocation: UserLocation) => !userLocation.countryCode.includes(userLocation.countryCode))
    );
  }
}

export const checkCountryBlockage: CheckCountryBlockage = new CheckCountryBlockage(sharedSettings, userDataInfo);
