import { createStore, get, set, UseStore } from 'idb-keyval';
import { CookiesConsentType } from './cookies-consent-types';

export class CookiesConsentMemory {
  private _cookiesConsentStoreKey: string = 'cookies-consent';

  public async acceptedCookiesConsent(): Promise<CookiesConsentType[] | undefined> {
    return await get(this._cookiesConsentStoreKey, this._cookiesConsentStore);
  }

  public async setAcceptedCookiesConsent(consent: CookiesConsentType[]): Promise<void> {
    await set(this._cookiesConsentStoreKey, consent, this._cookiesConsentStore);
  }

  private _cookiesConsentStore: UseStore = createStore(this._cookiesConsentStoreKey, this._cookiesConsentStoreKey);
}

export const cookiesConsentMemory: CookiesConsentMemory = new CookiesConsentMemory();
